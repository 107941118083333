<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Usuário</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">

            <form (ngSubmit)="salvar(usuario)" #usuarioForm="ngForm" novalidate>
                <div class="card card-default color-pallete-box">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="fas fa-user"></i>
                            <a i18n> Dados do Usuário </a>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="nu_username">Usuário</label>
                                    <br>
                                    <span class="form-control">{{usuario.username}}</span>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="nu_email">E-mail</label>
                                    <input [(ngModel)]="usuario.email" name="email" type="email" class="form-control"
                                        id="nu_email" placeholder="Digite o e-mail" i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="nu_password">Senha</label>
                                    <input [(ngModel)]="usuario.password" (focus)="showRequirements = true"
                                        (blur)="showRequirements = false" type="password" class="form-control"
                                        id="nu_password" name="nu_password" placeholder="Digite a senha do usuário"
                                        i18n-placeholder />
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="showRequirements">
                                <app-info-padrao-senha></app-info-padrao-senha>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary float-right" i18n>Salvar</button>
                    </div>
                </div>
            </form>
        </section>
    </div>
</secured>