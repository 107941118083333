/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-padrao-senha.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./info-padrao-senha.component";
var styles_InfoPadraoSenhaComponent = [i0.styles];
var RenderType_InfoPadraoSenhaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoPadraoSenhaComponent, data: {} });
export { RenderType_InfoPadraoSenhaComponent as RenderType_InfoPadraoSenhaComponent };
export function View_InfoPadraoSenhaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "callout callout-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Para garantir uma senha forte, ela deve seguir os seguintes padr\u00F5es:"])), (_l()(), i1.ɵeld(3, 0, null, null, 12, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Deve conter pelo menos 8 caracteres e no m\u00E1ximo 64 caracteres. "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Deve conter pelo menos um d\u00EDgito (0-9). "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Deve conter pelo menos uma letra min\u00FAscula (a-z). "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Deve conter pelo menos uma letra mai\u00FAscula (A-Z). "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Deve conter pelo menos um caractere especial (@, #, $, %, ^, &, +, =). "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["N\u00E3o deve conter espa\u00E7os em branco (espa\u00E7os, tabula\u00E7\u00F5es, etc.). "])), (_l()(), i1.ɵeld(16, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Exemplo de senha v\u00E1lida: "])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\"SenhaSegura1@\""]))], null, null); }
export function View_InfoPadraoSenhaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-info-padrao-senha", [], null, null, null, View_InfoPadraoSenhaComponent_0, RenderType_InfoPadraoSenhaComponent)), i1.ɵdid(1, 114688, null, 0, i2.InfoPadraoSenhaComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InfoPadraoSenhaComponentNgFactory = i1.ɵccf("app-info-padrao-senha", i2.InfoPadraoSenhaComponent, View_InfoPadraoSenhaComponent_Host_0, {}, {}, []);
export { InfoPadraoSenhaComponentNgFactory as InfoPadraoSenhaComponentNgFactory };
