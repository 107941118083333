<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h5 i18n><i class="fa fa-list"></i>&nbsp;&nbsp;&nbsp;Discernimentos</h5>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12" style='text-align: center'>
                                    <table-component [cardBodyClass]="'table-responsive p-0'"
                                        [tableClass]="'table-striped'"
                                        [tituloSessao]="'Questionários disponíveis para informar parecer'" [cols]="19"
                                        [rows]="paginacaoConfig.size" [totalDePaginas]="paginacaoConfig.totalPages"
                                        (eventoBotao)="cliqueNoBotaoListener($event)">
                                        <tr tableheader>
                                            <th>Foto de perfil</th>
                                            <th>Nome</th>
                                            <th>Célula</th>
                                            <th>Nível Formativo</th>
                                            <th>Questionario</th>
                                            <th>Parecer</th>
                                            <th>Caminho Formativo (FC)</th>
                                            <th>Concorda?</th>
                                            <th>Considerações CL</th>
                                            <th>Caminho Formativo (Feedback)</th>
                                            <th>Parecer FC</th>
                                            <th>Justificativa FC</th>
                                            <th>Parecer CR</th>
                                            <th>Justificativa CR</th>
                                            <th>Parecer CL</th>
                                            <th>Justificativa CL</th>
                                            <th><i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                                                Discernimento GG </th>
                                            <th>Status Vocacional</th>
                                            <th></th>
                                        </tr>
                                        <tr tablebody *ngFor="let membro of membrosCelula"
                                            [ngStyle]="obterCorDaLinha(membro)">
                                            <td>
                                                <div class="widget-user-image">
                                                    <img class="profile-user-img img-fluid img-circle image-size-list elevation-2"
                                                        [src]="membro.thumbnail == null ? 'assets/images/ppl.png' : membro.thumbnail"
                                                        alt="User Avatar">
                                                </div>
                                            </td>
                                            <td>{{membro.pessoaNome}}</td>
                                            <td>{{membro.celulaNome}}</td>
                                            <td>{{membro.nivelFormativoNome}}</td>
                                            <td>
                                                <button *ngIf="membro.arquivo" class="btn btn-primary"
                                                    (click)="carregarArquivoPDF(membro.arquivo)" data-toggle="modal"
                                                    data-target="#modal-view-questionario" title="Questionario CAL"
                                                    alt="Questionario CAL">Visualizar</button>
                                                <button *ngIf="!membro.arquivo" class="btn btn-primary" disabled
                                                    title="Questionario CAL"
                                                    alt="Questionario CAL">{{membro.statusQuestionario}}</button>
                                            <td>
                                                <button *ngIf="membro.statusParecerFC == 'ENVIADO'"
                                                    [routerLink]="['/secured/parecer-cal-answer-default-view/', membro.idParecerFC ]"
                                                    class="btn btn-primary" title="Parecer CAL"
                                                    alt="Parecer CAL">Visualizar</button>
                                                <button *ngIf="membro.statusParecerFC != 'ENVIADO'"
                                                    class="btn btn-primary" disabled title="Parecer CAL"
                                                    alt="Parecer CAL">{{membro.statusParecerFC}}</button>
                                            </td>
                                            <td>
                                                {{membro.caminhoFormativoSugeridoFC}}
                                            </td>
                                            <td>
                                                <ng-container *ngIf="membro.editando">
                                                    <select class="form-control" id="nparecer_concorda"
                                                        [(ngModel)]="membro.consideracaoConcorda"
                                                        name="nparecer_concorda"
                                                        (change)="concordanciaConselhoLocal(membro)">
                                                        <option [ngValue]="null" disabled>Selecione uma opção</option>
                                                        <option [ngValue]="true" i18n>Sim</option>
                                                        <option [ngValue]="false" i18n>Não</option>
                                                    </select>
                                                </ng-container>
                                                <ng-container *ngIf="!membro.editando">
                                                    {{ membro.consideracaoConcorda ? 'Sim' : membro.consideracaoConcorda
                                                    == false ? 'Não' : ''}}
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="membro.editando && !membro.consideracaoConcorda">
                                                    <input [(ngModel)]="membro.consideracaoCL" class="form-control"
                                                        id="nparecer_consideracoescl"
                                                        (change)="copiarTextoConsideracaoConselhoLocalParaFeedback(membro)">
                                                </ng-container>
                                                <ng-container *ngIf="!membro.editando">
                                                    {{membro.consideracaoCL}}
                                                </ng-container>
                                            </td>
                                            <td>
                                                {{membro.caminhoFormativoFeedback}}
                                            </td>
                                            <td>
                                                {{membro.indicacaoFCNome}}
                                            </td>
                                            <td [title]="membro.justificativaFC">
                                                {{ truncateText(membro.justificativaFC) }}
                                            </td>
                                            <td>
                                                {{ membro.indicacaoCLNome}}
                                            </td>
                                            <td [title]="membro.justificativaCL">
                                                {{ truncateText(membro.justificativaCL) }}
                                            </td>
                                            <td>
                                                <ng-container *ngIf="membro.editando">
                                                    <select class="form-control" id="nparecer_indicacaoCR"
                                                        [(ngModel)]="membro.indicacaoCR" name="nparecer_indicacaoCR">
                                                        <option disabled [value]="null" i18n>Selecione</option>
                                                        <option [value]="1"
                                                            *ngIf="membro.questionarioId == 6 || membro.questionarioId == 7 || membro.questionarioId == 8"
                                                            i18n>Mudança de Nível</option>
                                                        <option [value]="2"
                                                            *ngIf="membro.questionarioId == 4 || membro.questionarioId == 6 || membro.questionarioId == 7"
                                                            i18n>Permanecer no Nível</option>
                                                        <option [value]="3" *ngIf="membro.questionarioId == 4" i18n>
                                                            Realizar Primeiras Promessas</option>
                                                        <option [value]="4" *ngIf="membro.questionarioId == 5" i18n>
                                                            Realizar Promessas Definitivas</option>
                                                        <option [value]="5" i18n>Desligamento CAL</option>
                                                        <option [value]="6" i18n>Outro</option>
                                                        
                                                    </select>
                                                </ng-container>
                                                <ng-container *ngIf="!membro.editando">
                                                    {{ membro.indicacaoCRNome}}
                                                </ng-container>
                                            </td>
                                            <ng-container *ngIf="membro.editando">
                                                <td>
                                                    <input type="text" maxlength="1500" id="justificativaCR"
                                                        name="justificativaCR"
                                                        [(ngModel)]="membro.justificativaCR" />&nbsp;
                                                </td>
                                            </ng-container>
                                            <ng-container *ngIf="!membro.editando">
                                                <td [title]="membro.justificativaCR">
                                                    {{ truncateText(membro.justificativaCR) }}
                                                </td>
                                            </ng-container>
                                            <td>
                                                <ng-container *ngIf="membro.editando">
                                                    <div class="checkbox icheck-concrete">
                                                        <input type="checkbox" id="isChecked" name="isChecked"
                                                            [(ngModel)]="membro.isDiscernimentoGG" />&nbsp;
                                                        <!-- Exibe o label apenas quando o checkbox estiver marcado -->
                                                        <label *ngIf="membro.isDiscernimentoGG"><i
                                                                class="fas fa-exclamation-triangle"
                                                                aria-hidden="true"></i></label>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="!membro.editando">
                                                    <div class="checkbox icheck-concrete">
                                                        <label *ngIf="membro.isDiscernimentoGG"><i
                                                                class="fas fa-exclamation-triangle"
                                                                aria-hidden="true"></i></label>
                                                    </div>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="membro.editando == true">
                                                    <select class="form-control" id="cores_status_vocacional"
                                                        [(ngModel)]="membro.corStatusVocacionalId"
                                                        name="nparecer_concorda">
                                                        <option [ngValue]="null">Selecione um Status</option>
                                                        <option [ngValue]="status.id"
                                                            *ngFor="let status of listaCorStatusVocacional">
                                                            {{status.nome}}</option>
                                                    </select>
                                                </ng-container>
                                                <ng-container *ngIf="!membro.editando">
                                                    {{membro.corStatusVocacionalNome}}
                                                </ng-container>
                                            </td>
                                            <td>
                                                <button *ngIf="membro.editando == true" class="btn btn-sm btn-success"
                                                    (click)="cadastrarConsideracaoConselhoLocal(membro); membro.editando == false"
                                                    title="Atualizar parecer" alt="Atualizar parecer"><i
                                                        class="fas fa-save"></i></button>
                                                <button *ngIf="membro.editando != true" class="btn btn-sm btn-primary"
                                                    (click)="membro.editando = true" title="Editar parecer"
                                                    alt="Editar parecer"
                                                    [disabled]="(membro.statusParecerFC != 'ENVIADO')">
                                                    <i class="fas fa-edit"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </table-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="modal-view-questionario" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Visualização do questionário</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <iframe *ngIf="sanitizedPdfUrl" [src]="sanitizedPdfUrl" width="100%" height="600px"
                                (contextmenu)="false;"></iframe>
                            <!-- <object [data]="sanitizedPdfUrl" type="application/pdf" width="100%" height="500px">
                                <p>Seu navegador não suporta exibição de PDFs.</p>
                            </object> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>