import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from "@angular/core";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
export class ParecerCasaComunitariaAutoridadeComponent {
    constructor(usuarioService, imagemService, pessoaService, casacomunitariaService, membroService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, elementoGrupoDominioLocalService, caminhoFormativoService, arquivoService, sanitizer, route, router, swtAlert2Service) {
        this.usuarioService = usuarioService;
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.casacomunitariaService = casacomunitariaService;
        this.membroService = membroService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.arquivoService = arquivoService;
        this.sanitizer = sanitizer;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.totalDePaginas = 1;
        this.pessoaBuilder = new PessoaBuilder();
        this.membrosCasaComunitaria = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    ngOnInit() {
        this.initializer();
        this.hasCasaComunitariaAssociada = false;
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.carregarUsuario();
            yield this.carregarPessoa();
            this.casacomunitariaId = Number(this.route.snapshot.paramMap.get("id"));
            yield this.carregarCorStatusVocacional();
            yield this.carregarMembrosCasaComunitaria();
        });
    }
    /*carregarQuestionarioConfiguracao(questionarioId: number) {
      const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
      this.missaoConfiguracaoService
        .obterConfiguracaoQuestionarioPorMissaoEQuestionario(
          missaoId,
          questionarioId
        )
        .subscribe(
          (questionarioConfiguracao) =>
            (this.questionarioConfiguracao = questionarioConfiguracao)
        );
    }*/
    carregarUsuario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuario = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarPessoa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(this.usuario.id);
                this.pessoa = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarCorStatusVocacional() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.listaCorStatusVocacional =
                    yield this.elementoGrupoDominioLocalService.buscarPorGrupoDominioNomeModuloFormacao("COR_STATUS_VOCACIONAL");
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarMembrosCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.membroService.listarTodosMembrosCasaComunitaria(this.casacomunitariaId);
                this.membrosCasaComunitaria = response.content;
                this.totalDePaginasMembros = 1;
                console.log(this.membrosCasaComunitaria);
                if (this.membrosCasaComunitaria.length > 0) {
                    const idMembros = this.membrosCasaComunitaria.map(membro => membro.id);
                    yield this.carregarQuestionarioMembro(idMembros);
                    console.log(this.questionariosMembros);
                }
                for (let i = 0; i < this.membrosCasaComunitaria.length; i++) {
                    let response = yield this.pessoaService.buscaPorId(this.membrosCasaComunitaria[i].pessoaId);
                    this.membrosCasaComunitaria[i].imagemId = response.entity.imagemId;
                    this.membrosCasaComunitaria[i].pessoaNome = response.entity.nome + ' ' + response.entity.sobrenome;
                    if (this.membrosCasaComunitaria[i].imagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.membrosCasaComunitaria[i].imagemId);
                        this.imagemService.createImage(blogImage, this.membrosCasaComunitaria[i]);
                    }
                    this.membrosCasaComunitaria[i].editando = false;
                    const questionario = this.questionariosMembros.filter((questionarioFormando) => questionarioFormando.id === this.membrosCasaComunitaria[i].id);
                    if (questionario.length > 0) {
                        this.membrosCasaComunitaria[i].statusQuestionario =
                            questionario[0].status_questionario;
                        this.membrosCasaComunitaria[i].idQuestionarioFormando =
                            questionario[0].questionarioFormandoId;
                        this.membrosCasaComunitaria[i].questionarioId = questionario[0].questionarioId;
                        this.membrosCasaComunitaria[i].statusParecerFC =
                            questionario[0].status_parecer_fc;
                        this.membrosCasaComunitaria[i].idParecerFC = questionario[0].parecerIdFC;
                        this.membrosCasaComunitaria[i].acompanhadoPor = questionario[0].acompanhadoPor;
                        this.membrosCasaComunitaria[i].acompanhadoPorNome =
                            questionario[0].acompanhadoPorNome;
                        this.membrosCasaComunitaria[i].arquivo = questionario[0].arquivo;
                        this.membrosCasaComunitaria[i].dataEnvioParecerFC =
                            questionario[0].dataEnvioParecerFC;
                        this.membrosCasaComunitaria[i].dataEnvioQuestionario =
                            questionario[0].dataEnvioQuestionario;
                        this.membrosCasaComunitaria[i].missaoAtualId = questionario[0].pessoamissaoAtualId;
                        this.membrosCasaComunitaria[i].questionarioNome = this.buscarNomeQuestionario(this.questionarioFormando.questionarioId);
                        //this.membrosCasaComunitaria[i].pedidoFormando = this.buscarNomePedido(questionario[0].indicacaoFC);
                        this.membrosCasaComunitaria[i].indicacaoFCNome = this.buscarNomePedido(questionario[0].indicacaoFC);
                        this.membrosCasaComunitaria[i].justificativaFC = questionario[0].justificativaFC;
                        this.membrosCasaComunitaria[i].indicacaoCD = questionario[0].indicacaoCD;
                        this.membrosCasaComunitaria[i].justificativaCD = questionario[0].justificativaCD;
                        this.membrosCasaComunitaria[i].indicacaoCL = questionario[0].indicacaoCL;
                        this.membrosCasaComunitaria[i].justificativaCL = questionario[0].justificativaCL;
                        this.membrosCasaComunitaria[i].isDiscernimentoGG = questionario[0].isDiscernimentoGG;
                        this.membrosCasaComunitaria[i].consideracaoConcorda = questionario[0].consideracaoConcorda;
                        this.membrosCasaComunitaria[i].caminhoFormativoFeedback = questionario[0].caminhoFormativoFeedback;
                        this.membrosCasaComunitaria[i].caminhoFormativoSugeridoFC = questionario[0].caminhoFormativoSugeridoFC;
                        this.membrosCasaComunitaria[i].consideracaoCL = questionario[0].consideracaoCL;
                        this.membrosCasaComunitaria[i].corStatusVocacionalId = questionario[0].corStatusVocacionalId;
                        if (questionario[0].corStatusVocacionalId) {
                            const cor = this.listaCorStatusVocacional.find(c => c.id === questionario[0].corStatusVocacionalId);
                            this.membrosCasaComunitaria[i].corStatusVocacionalValor = cor ? cor.valor : undefined;
                            this.membrosCasaComunitaria[i].corStatusVocacionalNome = cor ? cor.nome : undefined;
                        }
                    }
                    if (this.membrosCasaComunitaria[i].statusQuestionario == "NAOINICIADO"
                        || this.membrosCasaComunitaria[i].statusQuestionario == undefined
                        || this.membrosCasaComunitaria[i].statusQuestionario == null) {
                        this.membrosCasaComunitaria[i].statusQuestionario =
                            "Não Iniciado";
                    }
                    if (this.membrosCasaComunitaria[i].statusParecerFC == "NAOINICIADO"
                        || this.membrosCasaComunitaria[i].statusParecerFC == undefined
                        || this.membrosCasaComunitaria[i].statusParecerFC == null) {
                        this.membrosCasaComunitaria[i].statusParecerFC = "Não Iniciado";
                    }
                }
                this.membrosCasaComunitaria = this.membrosCasaComunitaria.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    buscarNomePedido(idPedido) {
        let nomePedido = '';
        if (idPedido) {
            switch (idPedido) {
                case 1:
                    nomePedido = 'Mudança de Nível';
                    break;
                case 2:
                    nomePedido = 'Permanecer no Nível';
                    break;
                case 3:
                    nomePedido = 'Realizar Primeiras Promessas';
                    break;
                case 4:
                    nomePedido = 'Realizar Promessas Definitivas';
                    break;
                case 5:
                    nomePedido = 'Desligamento CAL';
                    break;
                case 6:
                    nomePedido = 'Outro';
                    break;
                case 7:
                    nomePedido = 'Permanecer no P1';
                    break;
                case 8:
                    nomePedido = 'Permanecer no P2';
                    break;
                case 9:
                    nomePedido = 'Ingressar no P2';
                    break;
                case 10:
                    nomePedido = 'Ingressar no D';
                    break;
                case 11:
                    nomePedido = 'Permanecer no D1';
                    break;
                case 12:
                    nomePedido = 'Ingressar no D';
                    break;
                case 13:
                    nomePedido = 'Permanecer no D2';
                    break;
                case 14:
                    nomePedido = 'Renovar as Promessas';
                    break;
                default:
                    nomePedido = null;
                    break;
            }
        }
        return nomePedido;
    }
    buscarNomeQuestionario(idQuestionario) {
        let nomeQuestionario = '';
        if (idQuestionario) {
            switch (idQuestionario) {
                case 4:
                    nomeQuestionario = 'Pedido para as Primeiras Promessas - CA';
                    break;
                case 5:
                    nomeQuestionario = 'Pedido para as Promessas Definitivas - CA';
                    break;
                case 6:
                    nomeQuestionario = 'Postulantes/Ingresso no Discipulado - CA';
                    break;
                case 7:
                    nomeQuestionario = 'Discipulos de Primeiro Ano - CA';
                    break;
                case 8:
                    nomeQuestionario = 'Renovação das Promessas - CA';
                    break;
                default:
                    nomeQuestionario = null;
                    break;
            }
        }
        return nomeQuestionario;
    }
    carregarQuestionarioMembro(idMembros) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.questionarioFormandoService.buscarPorMembrosIdGenerico(idMembros);
                this.questionariosMembros = response;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarArquivoPDF(arquivoPDFNome) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const blobResponse = yield this.arquivoService
                .obterDetalhesArquivoPDF(arquivoPDFNome)
                .toPromise();
            const url = URL.createObjectURL(blobResponse);
            this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        });
    }
    cadastrarConsideracaoConselhoLocal(membro) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.atualizarQuestionarioFormando(membro);
                /*await this.caminhoFormativoService.cadastrarConsideracaoConselhoLocal(
                  membro
                ); */
                yield this.carregarMembrosCasaComunitaria();
                this.swtAlert2Service.successAlert("Parecer de consideração do Conselho Local atualizado com sucesso!");
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarQuestionarioFormando(membro) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let responseQuestionario = yield this.questionarioFormandoService.buscarPorId(membro.idQuestionarioFormando);
                this.questionarioFormando = responseQuestionario;
                if (this.questionarioFormando.id != null) {
                    this.questionarioFormando.indicacaoCL = membro.indicacaoCL;
                    this.questionarioFormando.justificativaCL = membro.justificativaCL;
                    this.questionarioFormando.isDiscernimentoGG = membro.isDiscernimentoGG;
                    this.questionarioFormando.consideracaoConcorda = membro.consideracaoConcorda;
                    this.questionarioFormando.caminhoFormativoFeedback = membro.caminhoFormativoFeedback;
                    this.questionarioFormando.caminhoFormativoSugeridoFC = membro.caminhoFormativoSugeridoFC;
                    this.questionarioFormando.consideracaoCL = membro.consideracaoCL;
                    this.questionarioFormando.corStatusVocacionalId = membro.corStatusVocacionalId;
                    let response = yield this.questionarioFormandoService.atualizar(this.questionarioFormando.id, this.questionarioFormando);
                    this.questionarioFormando = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cliqueNoBotaoListener(botao) {
        this.carregarMembrosCasaComunitaria();
    }
    concordanciaConselhoLocal(membro) {
        if (membro.consideracaoConcorda === true) {
            membro.caminhoFormativoFeedback =
                membro.caminhoFormativoSugeridoFC;
            membro.consideracaoCL = "";
        }
        else {
            membro.caminhoFormativoFeedback = "";
        }
    }
    copiarTextoConsideracaoConselhoLocalParaFeedback(membro) {
        membro.caminhoFormativoFeedback = membro.consideracaoCL;
    }
    obterCorDaLinha(membro) {
        let cssObject = {};
        if (membro.corStatusVocacionalValor != null) {
            cssObject["background-color"] = membro.corStatusVocacionalValor;
        }
        if (membro.corStatusVocacionalNome != null &&
            membro.corStatusVocacionalNome.localeCompare("Desligamento") == 0) {
            cssObject["color"] = "#fff";
        }
        return cssObject;
    }
    truncateText(text, limit = 50) {
        if (!text) {
            return '';
        }
        return text.length > limit ? text.substring(0, limit) + '...' : text;
    }
}
