import { Component, OnInit } from '@angular/core';
import { EsqueceuSenhaService } from './service/esqueceusenha.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
  selector: 'app-novasenha.esqueceusenha',
  templateUrl: './novasenha.esqueceusenha.component.html',
})
export class NovaSenhaEsqueceuSenhaComponent implements OnInit {
  public newPass: any;
  public token: string;
  public showRequirements: boolean = false;

  constructor(
    private esqueceuSenhaService: EsqueceuSenhaService,
    private route: ActivatedRoute,
    protected router: Router,
    public swtAlert2Service: SwtAlert2Service
  ) {
    this.newPass = {
      password: '',
      passwordConfirmation: '',
    };
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');
  }

  async atualizarSenha(): Promise<any> {
    if (
      this.newPass.password.localeCompare(this.newPass.passwordConfirmation) !=
      0
    ) {
      this.swtAlert2Service.warningAlert(
        'As senhas informadas nos 2 não conincidem, reescreva a nova senha em ambos os campos para confirmar a atualização.'
      );

      return;
    }

    const regexPassValidator =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{8,64}$/;

    if (!regexPassValidator.test(this.newPass.password)) {
      this.swtAlert2Service.warningAlert(
        `Sua senha não cumpre todos os requisitos solicitados para uma senha forte.<br>
         Veja os requisitos para a senha e atualize-a de modo a cumprir com todos os requisitos solicitados.
        `
      );
      this.showRequirements = true;
      return;
    }

    let request = {
      token: this.token,
      novaSenha: this.newPass.password,
    };

    try {
      let response = await this.esqueceuSenhaService.atualizarSenha(request);
      this.swtAlert2Service.successAlert(response.message);
      setTimeout(() => {
        this.router.navigate(['../login']);
      }, 5000);
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error);
    }
  }
}
