<body class="hold-transition login-page">
    <div class="login-page-item">

        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="card card-default color-pallete-box">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="fas fa-user"></i>
                            <a i18n> Recuperação de senha </a>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="showRequirements">
                                <app-info-padrao-senha></app-info-padrao-senha>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="form-group">
                                    <label i18n for="new_pass">Nova Senha</label>
                                    <input [(ngModel)]="newPass.password" (focus)="showRequirements = true"
                                        (blur)="showRequirements = false" name="password" type="password"
                                        class="form-control" id="new_pass" placeholder="Digite a nova senha"
                                        i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="form-group">
                                    <label i18n for="new_pass_confirm">Repita a Nova Senha</label>
                                    <input [(ngModel)]="newPass.passwordConfirmation" name="password" type="password"
                                        class="form-control" id="new_pass_confirm" placeholder="Digite a nova senha"
                                        i18n-placeholder>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <button (click)="atualizarSenha()" class="btn btn-primary float-right">Atualizar
                                    Senha</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>