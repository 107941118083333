import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { UsuarioBuilder } from 'src/app/builder/usuario.builder';
export class UsuarioEditComponent {
    constructor(usuarioService, autorizacaoService, swtAlert2Service, route, router) {
        this.usuarioService = usuarioService;
        this.autorizacaoService = autorizacaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.route = route;
        this.router = router;
        this.autorizacaoSelecionada = null;
        this.showRequirements = false;
        this.autorizacoes = new Array();
        this.autorizacoesVinculadas = new Array();
        this.usuarioBuilder = new UsuarioBuilder();
        this.usuario = this.usuarioBuilder.getInstance();
        this.vinculacaoAutorizacao = {
            usuarioId: this.usuarioId,
            autorizacaoId: null,
        };
    }
    ngOnInit() {
        this.usuarioId = Number(this.route.snapshot.paramMap.get('id'));
        this.carregarUsuario(this.usuarioId);
        this.carregarAutorizacoes();
        this.carregarAutorizacoesVinculadas();
    }
    getUsuarioAtual() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuarioAtual = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarAutorizacoes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.autorizacaoService.findAll();
                this.autorizacoes = response.entity;
                this.carregarUsuario(this.usuarioId);
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
                console.log(e);
            }
        });
    }
    carregarAutorizacoesVinculadas() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.autorizacaoService.autorizacoesPorUsuarioId(this.usuarioId);
                this.autorizacoesVinculadas = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
                console.log(e);
            }
        });
    }
    adicionarAutorizacao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.vinculacaoAutorizacao.autorizacaoId = this.autorizacaoSelecionada;
            this.vinculacaoAutorizacao.usuarioId = this.usuarioId;
            try {
                let response = yield this.autorizacaoService.vincularUsuario(this.vinculacaoAutorizacao);
                this.swtAlert2Service.successAlert(response.message);
                this.vinculacaoAutorizacao = {
                    usuarioId: this.usuarioId,
                    autorizacaoId: null,
                };
                this.carregarAutorizacoesVinculadas();
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
                console.log(e);
            }
        });
    }
    removerAutorizacao(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.vinculacaoAutorizacao.autorizacaoId = id;
            this.vinculacaoAutorizacao.usuarioId = this.usuarioId;
            try {
                let response = yield this.autorizacaoService.desvincularUsuario(this.vinculacaoAutorizacao);
                this.swtAlert2Service.successAlert(response.message);
                this.vinculacaoAutorizacao = {
                    usuarioId: this.usuarioId,
                    autorizacaoId: null,
                };
                this.carregarAutorizacoesVinculadas();
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
                console.log(e);
            }
        });
    }
    carregarUsuario(id) {
        this.usuarioService
            .find(id)
            .then((response) => {
            this.usuario = response.entity;
        })
            .catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    salvar(usuario) {
        if (this.autorizacaoSelecionada != null) {
            usuario.autorizacoes.push(this.autorizacaoSelecionada);
        }
        const regexPassValidator = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{8,64}$/;
        if (usuario.password && !regexPassValidator.test(usuario.password)) {
            this.swtAlert2Service.warningAlert(`Sua senha não cumpre todos os requisitos solicitados para uma senha forte.<br>
       Veja os requisitos para a senha e atualize-a de modo a cumprir com todos os requisitos solicitados.
      `);
            this.showRequirements = true;
            return;
        }
        this.usuarioService
            .update(usuario.id, usuario)
            .then((response) => {
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/usuarios']);
            }, 2000);
        })
            .catch((err) => {
            this.swtAlert2Service.errorAlert(err.error);
        });
    }
}
