import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/model/usuario.model';
import { UsuarioService } from './service/usuario.service';
import { Autorizacao } from 'src/app/model/autorizacao.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioBuilder } from 'src/app/builder/usuario.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { AutorizacaoLocalService } from '../../local/autorizacao/service/autorizacao.local.service';

@Component({
  selector: 'usuario-edit',
  templateUrl: './usuario.edit.component.html',
})
export class UsuarioEditComponent implements OnInit {
  public autorizacoes: Array<Autorizacao>;
  public autorizacoesVinculadas: Array<Autorizacao>;
  public usuario: Usuario;
  public usuarioBuilder: UsuarioBuilder;
  public autorizacaoSelecionada: number = null;
  public total: number;
  public usuarioId: number;
  public usuarioAtual: Usuario;
  public vinculacaoAutorizacao: any;
  public showRequirements: boolean = false;

  constructor(
    private usuarioService: UsuarioService,
    private autorizacaoService: AutorizacaoLocalService,
    private swtAlert2Service: SwtAlert2Service,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.autorizacoes = new Array<Autorizacao>();
    this.autorizacoesVinculadas = new Array<Autorizacao>();
    this.usuarioBuilder = new UsuarioBuilder();
    this.usuario = this.usuarioBuilder.getInstance();
    this.vinculacaoAutorizacao = {
      usuarioId: this.usuarioId,
      autorizacaoId: null,
    };
  }

  ngOnInit() {
    this.usuarioId = Number(this.route.snapshot.paramMap.get('id'));
    this.carregarUsuario(this.usuarioId);
    this.carregarAutorizacoes();
    this.carregarAutorizacoesVinculadas();
  }

  async getUsuarioAtual(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuarioAtual = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarAutorizacoes(): Promise<any> {
    try {
      let response = await this.autorizacaoService.findAll();
      this.autorizacoes = response.entity;
      this.carregarUsuario(this.usuarioId);
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
      console.log(e);
    }
  }

  async carregarAutorizacoesVinculadas(): Promise<any> {
    try {
      let response = await this.autorizacaoService.autorizacoesPorUsuarioId(
        this.usuarioId
      );
      this.autorizacoesVinculadas = response.entity;
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
      console.log(e);
    }
  }

  async adicionarAutorizacao(): Promise<any> {
    this.vinculacaoAutorizacao.autorizacaoId = this.autorizacaoSelecionada;
    this.vinculacaoAutorizacao.usuarioId = this.usuarioId;

    try {
      let response = await this.autorizacaoService.vincularUsuario(
        this.vinculacaoAutorizacao
      );
      this.swtAlert2Service.successAlert(response.message);
      this.vinculacaoAutorizacao = {
        usuarioId: this.usuarioId,
        autorizacaoId: null,
      };
      this.carregarAutorizacoesVinculadas();
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
      console.log(e);
    }
  }

  async removerAutorizacao(id: any): Promise<any> {
    this.vinculacaoAutorizacao.autorizacaoId = id;
    this.vinculacaoAutorizacao.usuarioId = this.usuarioId;

    try {
      let response = await this.autorizacaoService.desvincularUsuario(
        this.vinculacaoAutorizacao
      );
      this.swtAlert2Service.successAlert(response.message);
      this.vinculacaoAutorizacao = {
        usuarioId: this.usuarioId,
        autorizacaoId: null,
      };
      this.carregarAutorizacoesVinculadas();
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
      console.log(e);
    }
  }

  carregarUsuario(id: number): void {
    this.usuarioService
      .find(id)
      .then((response: any) => {
        this.usuario = response.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
        console.log(err);
      });
  }

  salvar(usuario: Usuario): void {
    if (this.autorizacaoSelecionada != null) {
      usuario.autorizacoes.push(this.autorizacaoSelecionada);
    }

    const regexPassValidator =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{8,64}$/;

    if (usuario.password && !regexPassValidator.test(usuario.password)) {
      this.swtAlert2Service.warningAlert(
        `Sua senha não cumpre todos os requisitos solicitados para uma senha forte.<br>
       Veja os requisitos para a senha e atualize-a de modo a cumprir com todos os requisitos solicitados.
      `
      );
      this.showRequirements = true;
      return;
    }

    this.usuarioService
      .update(usuario.id, usuario)
      .then((response: any) => {
        this.swtAlert2Service.successAlert(response.message);
        setTimeout(() => {
          this.router.navigate(['../secured/usuarios']);
        }, 2000);
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error);
      });
  }
}
