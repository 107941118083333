import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class NovaSenhaEsqueceuSenhaComponent {
    constructor(esqueceuSenhaService, route, router, swtAlert2Service) {
        this.esqueceuSenhaService = esqueceuSenhaService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.showRequirements = false;
        this.newPass = {
            password: '',
            passwordConfirmation: '',
        };
    }
    ngOnInit() {
        this.token = this.route.snapshot.paramMap.get('token');
    }
    atualizarSenha() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.newPass.password.localeCompare(this.newPass.passwordConfirmation) !=
                0) {
                this.swtAlert2Service.warningAlert('As senhas informadas nos 2 não conincidem, reescreva a nova senha em ambos os campos para confirmar a atualização.');
                return;
            }
            const regexPassValidator = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{8,64}$/;
            if (!regexPassValidator.test(this.newPass.password)) {
                this.swtAlert2Service.warningAlert(`Sua senha não cumpre todos os requisitos solicitados para uma senha forte.<br>
         Veja os requisitos para a senha e atualize-a de modo a cumprir com todos os requisitos solicitados.
        `);
                this.showRequirements = true;
                return;
            }
            let request = {
                token: this.token,
                novaSenha: this.newPass.password,
            };
            try {
                let response = yield this.esqueceuSenhaService.atualizarSenha(request);
                this.swtAlert2Service.successAlert(response.message);
                setTimeout(() => {
                    this.router.navigate(['../login']);
                }, 5000);
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error);
            }
        });
    }
}
