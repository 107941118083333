import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MembroComunidadeBuilder } from 'src/app/builder/membro.comunidade.builder';
import { ParecerResponsavelBuilder } from 'src/app/builder/parecerresponsavel.builder';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { QuestionarioFormandoBuilder } from 'src/app/builder/questionarioformando.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Celula } from 'src/app/model/celula.model';
import { MembroComunidade } from 'src/app/model/membro.comunidade.model';
import { ParecerResponsavel } from 'src/app/model/parecerresponsavel.model';
import { Pessoa } from 'src/app/model/pessoa.model';
import { QuestionarioFormando } from 'src/app/model/questionarioformando.model';
import { Usuario } from 'src/app/model/usuario.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UsuarioService } from 'src/app/secured/wop/usuario/service/usuario.service';
import { ImagemService } from 'src/app/secured/wop/imagem/service/imagem.service';
import { PessoaService } from 'src/app/secured/wop/pessoa/service/pessoa.service';
import { CelulaService } from 'src/app/secured/wop/celula/service/celula.service';
import { MembroComunidadeService } from 'src/app/secured/wop/celula/service/membro.comunidade.service';
import { QuestionarioFormandoService } from '../service/questionarioformando.service';
import { ParecerResponsavelService } from '../../parecer/service/parecerresponsavel.service';
import { ElementoGrupoDominioLocalService } from '../../grupodominio/service/elementogrupodominio.local.service';
import { CaminhoFormativoService } from '../service/caminhoformativo.service';
import { DiscernimentoService } from '../../discernimentos/service/discernimento.service';
import { ArquivoService } from '../service/arquivo.service';

@Component({
  selector: 'app-parecer',
  templateUrl: './parecer.rr.autoridade.component.html',
  styleUrls: [],
})
export class ParecerResponsavelRegionalAutoridadeComponent implements OnInit {
  public usuario: Usuario;
  public pessoa: Pessoa;
  public celula: Celula;
  public membroComunidadeBuilder: MembroComunidadeBuilder;
  public membroComunidade: MembroComunidade;
  public membrosCelula: Array<any>;
  public pessoaBuilder: PessoaBuilder;

  public paginacaoConfig: any;
  public buscaCelula: any;

  public totalDePaginasMembros: number;
  public hasCelulaAssociada: boolean;

  public questionarioFormando: QuestionarioFormando;
  public parecerResponsavel: ParecerResponsavel;
  public questionarioId: number;
  public questionariosMembros: Array<any>;

  public listaCorStatusVocacional!: Array<any>;

  public sanitizedPdfUrl: SafeResourceUrl;
  @ViewChild('pdfViewer', { static: false })
  pdfViewer: ElementRef;

  constructor(
    public usuarioService: UsuarioService,
    public imagemService: ImagemService,
    public pessoaService: PessoaService,
    public celulaService: CelulaService,
    public membroService: MembroComunidadeService,
    public questionarioFormandoBuilder: QuestionarioFormandoBuilder,
    public questionarioFormandoService: QuestionarioFormandoService,
    public parecerResponsavelBuilder: ParecerResponsavelBuilder,
    public parecerResponsavelService: ParecerResponsavelService,
    public elementoGrupoDominioLocalService: ElementoGrupoDominioLocalService,
    public caminhoFormativoService: CaminhoFormativoService,
    public discernimentoService: DiscernimentoService,
    private arquivoService: ArquivoService,
    private sanitizer: DomSanitizer,
    public route: ActivatedRoute,
    public router: Router,
    public swtAlert2Service: SwtAlert2Service
  ) {
    this.pessoaBuilder = new PessoaBuilder();
    this.membrosCelula = new Array<any>();
    this.membroComunidadeBuilder = new MembroComunidadeBuilder();
    this.membroComunidade = this.membroComunidadeBuilder.getInstance();
    this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
    this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
  }

  ngOnInit(): void {
    this.initializer();
    this.hasCelulaAssociada = false;
  }

  async initializer(): Promise<any> {
    this.buscaCelula = {
      nome: null,
      ano: null,
      faseId: null,
      regionalId: null,
      missaoId: null,
      centroEvangelizacaoId: null,
      paisId: null,
      estadoId: null,
      pageNumber: 0,
      quantityOfElements: 300,
    };
    this.paginacaoConfig = {
      page: 0,
      size: 10,
      totalPages: 0,
      totalElements: 0,
    };
    await this.carregarUsuario();
    await this.carregarPessoa();
    this.questionarioId = Number(this.route.snapshot.paramMap.get('id'));
    await this.carregarCorStatusVocacional();
    await this.carregarMembrosCelula();
  }

  async carregarUsuario(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuario = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarPessoa(): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorUsuarioId(
        this.usuario.id
      );
      this.pessoa = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarCorStatusVocacional(): Promise<any> {
    try {
      this.listaCorStatusVocacional =
        await this.elementoGrupoDominioLocalService.buscarPorGrupoDominioNomeModuloFormacao(
          'COR_STATUS_VOCACIONAL'
        );
    } catch (e) {
      console.log(e);
    }
  }

  async carregarMembrosCelula(): Promise<any> {
    try {
      let response =
        await this.discernimentoService.obterMembrosParaDiscernimentoDoResponsavelRegional(
          this.questionarioId,
          this.paginacaoConfig.page,
          this.paginacaoConfig.size
        );
      this.membrosCelula = response.content;
      this.paginacaoConfig.totalPages = response.totalPages;
      this.paginacaoConfig.totalElements = response.totalElements;

      if (this.membrosCelula.length > 0) {
        this.membrosCelula = this.membrosCelula.sort((a, b) =>
          a.pessoaNome.localeCompare(b.pessoaNome)
        );
      }

      for (let i = 0; i < this.membrosCelula.length; i++) {
        if (this.membrosCelula[i].imagemId != null) {
          let blogImage = await this.getPessoaImagem(
            this.membrosCelula[i].imagemId
          );
          this.imagemService.createImage(blogImage, this.membrosCelula[i]);
        }

        this.membrosCelula[i].editando = false;

        this.membrosCelula[i].indicacaoFCNome = this.buscarNomePedido(
          this.membrosCelula[i].indicacaoFC
        );
        this.membrosCelula[i].indicacaoCLNome = this.buscarNomePedido(
          this.membrosCelula[i].indicacaoCL
        );
        this.membrosCelula[i].indicacaoCRNome = this.buscarNomePedido(
          this.membrosCelula[i].indicacaoCR
        );

        if (this.membrosCelula[i].corStatusVocacionalId) {
          const cor = this.listaCorStatusVocacional.find(
            (c) => c.id === this.membrosCelula[i].corStatusVocacionalId
          );
          this.membrosCelula[i].corStatusVocacionalValor = cor
            ? cor.valor
            : undefined;
          this.membrosCelula[i].corStatusVocacionalNome = cor
            ? cor.nome
            : undefined;
        }

        if (
          this.membrosCelula[i].statusQuestionario == 'NAOINICIADO' ||
          this.membrosCelula[i].statusQuestionario == undefined ||
          this.membrosCelula[i].statusQuestionario == null
        ) {
          this.membrosCelula[i].statusQuestionario = 'Não Iniciado';
        }
        if (
          this.membrosCelula[i].statusParecerFC == 'NAOINICIADO' ||
          this.membrosCelula[i].statusParecerFC == undefined ||
          this.membrosCelula[i].statusParecerFC == null
        ) {
          this.membrosCelula[i].statusParecerFC = 'Não Iniciado';
        }
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  buscarNomePedido(idPedido: number): string {
    let nomePedido = '';
    if (idPedido) {
      switch (idPedido) {
        case 1:
          nomePedido = 'Mudança de Nível';
          break;
        case 2:
          nomePedido = 'Permanecer no Nível';
          break;
        case 3:
          nomePedido = 'Realizar Primeiras Promessas';
          break;
        case 4:
          nomePedido = 'Realizar Promessas Definitivas';
          break;
        case 5:
          nomePedido = 'Desligamento CAL';
          break;
        case 6:
          nomePedido = 'Outro';
          break;
        case 7:
          nomePedido = 'Permanecer no P1';
          break;   
        case 8:
          nomePedido = 'Permanecer no P2';
          break;   
        case 9:
          nomePedido = 'Ingressar no P2';
          break;   
        case 10:
          nomePedido = 'Ingressar no D';
          break;   
        case 11:
          nomePedido = 'Permanecer no D1';
          break;   
        case 12:
          nomePedido = 'Ingressar no D';
          break;   
        case 13:
          nomePedido = 'Permanecer no D2';
          break;   
        case 14:
          nomePedido = 'Renovar as Promessas';
          break;          
        default:
          nomePedido = null;
          break;
      }
    }
    return nomePedido;
  }

  buscarNomeQuestionario(idQuestionario: number): string {
    let nomeQuestionario = '';
    if (idQuestionario) {
      switch (idQuestionario) {
        case 4:
          nomeQuestionario = 'Pedido para as Primeiras Promessas - CA';
          break;
        case 5:
          nomeQuestionario = 'Pedido para as Promessas Definitivas - CA';
          break;
        case 6:
          nomeQuestionario = 'Postulantes/Ingresso no Discipulado - CA';
          break;
        case 7:
          nomeQuestionario = 'Discipulos de Primeiro Ano - CA';
          break;
        case 8:
          nomeQuestionario = 'Renovação das Promessas - CA';
          break;
        default:
          nomeQuestionario = null;
          break;
      }
    }
    return nomeQuestionario;
  }

  async carregarQuestionarioMembro(idMembros: any): Promise<any> {
    try {
      let response = await this.questionarioFormandoService.buscarPorMembrosId(
        idMembros
      );
      this.questionariosMembros = response;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarArquivoPDF(arquivoPDFNome: string): Promise<any> {
    const blobResponse = await this.arquivoService
      .obterDetalhesArquivoPDF(arquivoPDFNome)
      .toPromise();

    const url = URL.createObjectURL(blobResponse);
    this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  async cadastrarConsideracaoConselhoLocal(membro): Promise<any> {
    try {
      await this.atualizarQuestionarioFormando(membro);
      /*await this.caminhoFormativoService.cadastrarConsideracaoConselhoLocal(
        membro
      ); */
      await this.carregarMembrosCelula();
      this.swtAlert2Service.successAlert(
        'Parecer de consideração do Conselho Local atualizado com sucesso!'
      );
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarQuestionarioFormando(membro: any): Promise<any> {
    try {
      let responseQuestionario =
        await this.questionarioFormandoService.buscarPorId(
          membro.idQuestionarioFormando
        );
      this.questionarioFormando = responseQuestionario;
      if (this.questionarioFormando.id != null) {
        this.questionarioFormando.indicacaoCR = membro.indicacaoCR;
        this.questionarioFormando.justificativaCR = membro.justificativaCR;
        this.questionarioFormando.isDiscernimentoGG = membro.isDiscernimentoGG;
        this.questionarioFormando.consideracaoConcorda =
          membro.consideracaoConcorda;
        this.questionarioFormando.caminhoFormativoFeedback =
          membro.caminhoFormativoFeedback;
        this.questionarioFormando.caminhoFormativoSugeridoFC =
          membro.caminhoFormativoSugeridoFC;
        this.questionarioFormando.consideracaoCL = membro.consideracaoCL;
        this.questionarioFormando.corStatusVocacionalId =
          membro.corStatusVocacionalId;
        let response = await this.questionarioFormandoService.atualizar(
          this.questionarioFormando.id,
          this.questionarioFormando
        );
        this.questionarioFormando = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async getPessoaImagem(id: number): Promise<any> {
    try {
      let response = await this.imagemService.getPessoaPhoto(id);
      return response.body;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  cliqueNoBotaoListener(botao) {
    this.paginacaoConfig.page = botao.numero - 1;
    this.carregarMembrosCelula();
  }

  concordanciaConselhoLocal(membro: any) {
    if (membro.consideracaoConcorda === true) {
      membro.caminhoFormativoFeedback = membro.caminhoFormativoSugeridoFC;
      membro.consideracaoCL = '';
    } else {
      membro.caminhoFormativoFeedback = '';
    }
  }

  copiarTextoConsideracaoConselhoLocalParaFeedback(membro: any) {
    membro.caminhoFormativoFeedback = membro.consideracaoCL;
  }

  obterCorDaLinha(membro: any) {
    let cssObject = {};

    if (membro.corStatusVocacionalValor != null) {
      cssObject['background-color'] = membro.corStatusVocacionalValor;
    }

    if (
      membro.corStatusVocacionalNome != null &&
      membro.corStatusVocacionalNome.localeCompare('Desligamento') == 0
    ) {
      cssObject['color'] = '#fff';
    }

    return cssObject;
  }

  truncateText(text: string | null | undefined, limit: number = 50): string {
    if (!text) {
      return '';
    }
    return text.length > limit ? text.substring(0, limit) + '...' : text;
  }
}
