<div class="callout callout-info">
  <h5>Para garantir uma senha forte, ela deve seguir os seguintes padrões:</h5>
  <ul>
    <li>Deve conter pelo menos 8 caracteres e no máximo 64 caracteres. </li>
    <li>Deve conter pelo menos um dígito (0-9). </li>
    <li>Deve conter pelo menos uma letra minúscula (a-z). </li>
    <li>Deve conter pelo menos uma letra maiúscula (A-Z). </li>
    <li>Deve conter pelo menos um caractere especial (@, #, $, %, ^, &, +, =). </li>
    <li>Não deve conter espaços em branco (espaços, tabulações, etc.). </li>
  </ul>
  <p>Exemplo de senha válida: <strong>"SenhaSegura1@"</strong></p>
</div>