import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EsqueceuSenhaService } from './service/esqueceusenha.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NovaSenhaEsqueceuSenhaComponent } from './novasenha.esqueceusenha.component';
import { EsqueceusenhaRoutingModule } from './esqueceusenha-routing.module';
import { InfoPadraoSenhaComponent } from './info-padrao-senha/info-padrao-senha.component';

@NgModule({
  declarations: [NovaSenhaEsqueceuSenhaComponent, InfoPadraoSenhaComponent],
  imports: [
    EsqueceusenhaRoutingModule,
    FormsModule,
    CommonModule,
    NgxSummernoteModule,
  ],
  exports: [InfoPadraoSenhaComponent],
  providers: [EsqueceuSenhaService, SwtAlert2Service],
})
export class EsqueceusenhaModule {}
