import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-padrao-senha',
  templateUrl: './info-padrao-senha.component.html',
  styleUrls: ['./info-padrao-senha.component.css'],
})
export class InfoPadraoSenhaComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
